import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { faMapPin, faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

const SocialIcon = ({ icon, className, href }) => (
  <a href={href}>
    <FontAwesomeIcon
      className={`${className ?? ""} hover:text-blue-800 cursor-pointer`}
      icon={icon}
    />
  </a>
)

const Footer = () => (
  <footer className="bg-white h-64 w-full text-gray-600">
    <div className="flex justify-center items-center h-full">
      <div className="container flex flex-col-reverse md:flex-row md:justify-between justify-around items-center h-full">
        <div>
          <div>© {new Date().getFullYear()} Lefty's Inc.</div>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-6 text-sm">
          <div>
            <FontAwesomeIcon icon={faPhoneAlt} className="mr-3" />
            <a className="hover:text-blue-800" href="tel:+17723373212">
              +1 (772) 337-3212
            </a>
          </div>
          <div className="flex">
            <FontAwesomeIcon icon={faMapPin} className="mr-3" />
            <address>
              <a
                href="https://goo.gl/maps/cXHjp7xzPrVKf1QP6"
                className="hover:text-blue-800"
              >
                1034 SE Port St Lucie Blvd Port St. Lucie, FL 34952
              </a>
            </address>
          </div>
        </div>
        <div className="text-3xl">
          <SocialIcon
            className="mr-4"
            icon={faFacebook}
            href="https://www.facebook.com/Leftyswings/"
          />
          <SocialIcon
            icon={faInstagram}
            href="https://www.instagram.com/leftyswings/?hl=en"
          />
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
