import {
  faCar,
  faPhone,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import Logo from "./logo"

const HeaderLink = ({ children, url }) => (
  <Link to={url}>
    <li
      style={{ whiteSpace: "nowrap" }}
      className="mr-3 text-base text-gray-900 font-bold uppercase hover:text-gray-700 border-b-2 border-solid border-transparent hover:border-blue-500 mt-3"
    >
      {children}
    </li>
  </Link>
)

const MenuIcon = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
)

const Header = () => {
  return (
    <div className="w-full bg-white p-4 border-b border-solid border-gray-300">
      <div className="w-full gap-3 flex flex-col lg:flex-row justify-around items-center">
        <Link to="/">
          <div className="-mb-2 mr-2 flex items-center justify-center">
            <Logo />{" "}
            <span
              style={{ color: "#ce2029" }}
              className="ml-4 text-lg whitespace-nowrap font-bold"
            >
              Lefty's Wings and Grill
            </span>
          </div>
        </Link>

        {/* <div className="lg:hidden">
          <button
            className="p-2 rounded-lg border-2 border-gray-200 hover:bg-gray-50 hover:border-gray-100"
            onClick={toggleMenu}
          >
            <MenuIcon className="w-10 h-10 text-gray-600 hover:text-red-600" />
          </button>
          <div className="flex flex">
            {menuOpen && <div>Cori you better give me poon tonight</div>}
          </div>
        </div> */}

        <ul className="flex gap-3 flex-col lg:flex-row justify-around items-center">
          {/* <HeaderLink url="/">Home</HeaderLink> */}
          <HeaderLink url="/flavors">Wing Flavors</HeaderLink>
          <HeaderLink url="/menu">Menu</HeaderLink>
          <HeaderLink url="/location">
            Location & Hours{" "}
            <FontAwesomeIcon className="ml-px" icon={faPhone} />
          </HeaderLink>
        </ul>

        <div className="flex-col flex justify-center items-center md:justify-end md:flex-row">
          <a
            href="https://leftyswingsandgrill.alohaorderonline.com/"
            className="m-2 h-12 bg-blue-700 hover:bg-blue-800 p-2 rounded-lg md:rounded-tl-lg md:rounded-bl-lg text-white font-bold uppercase flex items-center"
          >
            <FontAwesomeIcon
              icon={faShoppingBag}
              className="ml-2 mr-6 text-xl"
            />
            <div className="flex flex-col">
              <span className="text-xs">Order</span>
              <span className="text-sm">Carryout</span>
            </div>
          </a>
          <Link
            to="/order"
            className="m-2 h-12 bg-blue-700 hover:bg-blue-800 p-2 rounded-lg md:rounded-tr-lg md:rounded-br-lg text-white font-bold uppercase flex items-center"
          >
            <FontAwesomeIcon icon={faCar} className="ml-2 mr-6 text-xl" />
            <div className="flex flex-col">
              <span className="text-xs">Order</span>
              <span className="text-sm">Delivery</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Header
